import "./Home.css";
import Input from "../../components/Input/Input.jsx";
import { useEffect, useState } from "react";
import Button from "../../components/Button/Button.jsx";
import Table from "../../components/Table/Table.jsx";
import TableRow from "../../components/Table/TableRow.jsx";
import TableColumn from "../../components/Table/TableColumn.jsx";
import { HiX } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";
import { TbMathGreater, TbMathLower } from "react-icons/tb";

const Home = () => {
    const [inputOne, setInputOne] = useState("");
    const [inputTwo, setInputTwo] = useState("");
    const [inputThree, setInputThree] = useState("");
    const [tableValues, setTableValues] = useState([]);
    const [message, setMessage] = useState("");
    const [validatedNumber, setValidatedNumber] = useState([]);
    const [validatedNumber2, setValidatedNumber2] = useState([]);
    const [isVisible, setVisible] = useState(false);
    const [maxNumber, setMaxNumber] = useState(16);
    const targets = [3, 6, 9, 2, 4, 8]

    const handleCalculate = () => {
        handleCalculate2([inputOne, inputTwo, inputThree]);
        handleCalculate2([inputOne, inputTwo, inputThree], 2);
        if (!inputOne || !inputTwo || !inputThree) {
            // setValidatedNumber([]);
            setTableValues([]);
            return;
        }
        setMessage("");
        const aux = [];
        const final = [];

        aux.push(inputOne + inputTwo - inputThree);
        aux.push(inputThree + inputOne - inputTwo);
        aux.push(inputTwo + inputThree - inputOne);

        aux.forEach((item) => {
            final.push([item, item - 1, item - 2, item - 3, item - 4, item - 5, item - 6])
            final.push(["-", item + 1, item + 2, item + 3, item + 4, item + 5, item + 6])
        });

        setTableValues(final);
    }

    useEffect(() => {
        if (inputOne || inputTwo || inputThree) handleCalculate();
    }, [maxNumber]);

    const handleCalculate2 = (inputs, loops = 3) => {
        const validated = [];
        inputs = inputs.filter(item => item);
        // const globalUsedNumbers = new Set(inputs);

        const canAchieveTarget = (currentSet, target, used = new Set()) => {
            if (target === 0 && used.size > 0) return true;

            if (!inputOne || !inputTwo || !inputThree) {
                const unique = new Set(currentSet);
                if (currentSet.length !== unique.size) return false;
            }

            for (let i = 0; i < currentSet.length; i++) {
                if (used.has(i)) continue;

                const num = currentSet[i];
                used.add(i);

                if (canAchieveTarget(currentSet, target - num, used) ||
                    canAchieveTarget(currentSet, target + num, used)) {
                    return true;
                }

                used.delete(i);
            }
            return false;
        };

        const canGenerateAllTargets = (numberSet) => {
            for (const target of targets) {
                if (!canAchieveTarget(numberSet, target, new Set())) {
                    return false;
                }
            }
            return true;
        };


        // Caso cuando recibimos 1 número
        if (inputs.length === 1) {
            let foundValid = false; // Flag para saber si ya encontramos al menos una solución válida

            for (let num1 = 1; num1 <= maxNumber; num1++) {
                // Solo saltamos si el número ya fue usado en una solución anterior
                // if (globalUsedNumbers.has(num1)) continue;

                for (let num2 = num1 + 1; num2 <= maxNumber; num2++) {
                    // Solo saltamos si el número ya fue usado en una solución anterior
                    // if (globalUsedNumbers.has(num2)) continue;

                    if (loops === 2) {
                        const currentSet = [...inputs, num1, num2];
                        if (canGenerateAllTargets(currentSet)) {
                            // Si ya encontramos una solución válida antes, agregamos estos números al set global
                            // if (foundValid) {
                            // if (!globalUsedNumbers.has(num1) && !globalUsedNumbers.has(num2)) {
                            validated.push([num1, num2]);
                            // globalUsedNumbers.add(num1);
                            // globalUsedNumbers.add(num2);
                            // }
                            // } else {
                            // Primera solución válida
                            // validated.push([num1, num2]);
                            // globalUsedNumbers.add(num1);
                            // globalUsedNumbers.add(num2);
                            // foundValid = true;
                            // }
                        }
                    } else {
                        // Similar lógica para loops === 3
                        for (let num3 = num2 + 1; num3 <= maxNumber; num3++) {
                            // if (globalUsedNumbers.has(num3)) continue;

                            const currentSet = [...inputs, num1, num2, num3];
                            if (canGenerateAllTargets(currentSet)) {
                                // if (foundValid) {
                                // if (!globalUsedNumbers.has(num1) && !globalUsedNumbers.has(num2) && !globalUsedNumbers.has(num3)) {
                                validated.push([num1, num2, num3]);
                                // globalUsedNumbers.add(num1);
                                // globalUsedNumbers.add(num2);
                                // globalUsedNumbers.add(num3);
                                // }
                                // } else {
                                // validated.push([num1, num2, num3]);
                                // globalUsedNumbers.add(num1);
                                // globalUsedNumbers.add(num2);
                                // globalUsedNumbers.add(num3);
                                // foundValid = true;
                                // }
                            }
                        }
                    }
                }
            }
        }
        // Caso cuando recibimos 2
        else if (inputs.length === 2) {
            for (let num1 = 1; num1 <= maxNumber; num1++) {
                // Saltamos si el número ya fue usado o está en inputs
                // if (globalUsedNumbers.has(num1)) continue;

                for (let num2 = num1 + 1; num2 <= maxNumber; num2++) {
                    // Saltamos si el número ya fue usado o está en inputs
                    // if (globalUsedNumbers.has(num2)) continue;

                    const currentSet = [...inputs, num1, num2];
                    if (canGenerateAllTargets(currentSet)) {
                        validated.push([num1, num2]);
                        // Agregamos los números usados al conjunto global
                        // globalUsedNumbers.add(num1);
                        // globalUsedNumbers.add(num2);
                        // Rompemos el loop interno ya que estos números ya no se pueden usar
                        break;
                    }
                }
            }
        }
        // Cuando recibimos 3
        else if (inputs.length === 3) {
            for (let number = 1; number <= maxNumber; number++) {
                const currentSet = [...inputs, number];
                if (canGenerateAllTargets(currentSet)) {
                    validated.push([number]);
                }
            }
            setValidatedNumber(validated.flat());
            setVisible(true);
            return;
        }

        if (loops === 3) {
            setValidatedNumber(validated);
        } else {
            setValidatedNumber2(validated)
        }
        setVisible(true);
    };


    const validateInput = (value) => {
        if (!value) return true;
        return value >= 1 && value <= maxNumber || value === "";
    }

    return (
        <div className={"homeContainer"}>

            <p className={"title"}>SUEÑO</p>


            <div>
                <p className={"maxNumberSuperContainer"}>Numero maximo</p>
                <div className={"maxNumberContainer"}>
                    <TbMathLower
                        onClick={() => setMaxNumber((prevState) => prevState === 0 ? 0 : prevState - 1)}
                        className={"maxNumberIcon"} />
                    <p>{maxNumber}</p>
                    <TbMathGreater
                        onClick={() => setMaxNumber((prevState) => prevState + 1)}
                        className={"maxNumberIcon"} />
                </div>
            </div>

            <div className={"inputContainer"}>
                <Input
                    validate={validateInput}
                    type={"number"}
                    value={inputOne}
                    onEnterKey={handleCalculate}
                    onChangeValue={(value) => {
                        setInputOne(value);
                        setVisible(false)
                        setTableValues([]);
                        setValidatedNumber([]);
                    }} />
                <Input
                    validate={validateInput}
                    type={"number"}
                    onEnterKey={handleCalculate}
                    value={inputTwo}
                    onChangeValue={(value) => {
                        setInputTwo(value);
                        setVisible(false)
                        setTableValues([]);
                        setValidatedNumber([]);
                    }} />
                <Input
                    validate={validateInput}
                    type={"number"}
                    onEnterKey={handleCalculate}
                    value={inputThree}
                    onChangeValue={(value) => {
                        setInputThree(value);
                        setTableValues([]);
                        setVisible(false)
                        setValidatedNumber([]);
                    }} />
            </div>

            {message && <p className={"message"}>{message}</p>}

            <div className={"inputContainer"}>
                <Button
                    onClick={() => {
                        setInputOne("");
                        setInputTwo("");
                        setInputThree("");
                        setValidatedNumber([]);
                        setTableValues([]);
                        setVisible(false);
                    }}
                    color={"danger"}>Borrar</Button>
                <Button onClick={handleCalculate}>Calcular</Button>
            </div>

            {tableValues.length ?
                <Table
                    headers={[0, 1, 2, 3, 4, 5, 6]}
                    items={tableValues}>
                    {(item, index) =>
                        <TableRow key={index}>
                            {item.map((column, index) =>
                                <TableColumn
                                    key={index}
                                    colSpan={1}>
                                    {column >= 0 ? column : ""}
                                </TableColumn>
                            )}
                        </TableRow>
                    }
                </Table>
                : ""
            }

            {isVisible &&
                <div className={"box-sub-container"}>
                    <p className={"box-number-gold"}>3 6 9</p>
                    <p className={"box-number-gold"}>2 4 6 8</p>
                </div>
            }

            {isVisible && inputOne && inputTwo && inputThree ?
                <div className={"box-container"}>
                    <div className={"box"}>

                        {maxNumber >= 1 &&
                            <div className={"box-sub-container-2"}>
                                {[1, 2, 3, 4].map((number) =>
                                    <p>{number} {validatedNumber.includes(number) ? <FaCheck className={"success-icon"} /> : <HiX className={"danger-icon"} />}</p>
                                )}
                            </div>
                        }

                        {maxNumber >= 5 &&
                            <div className={"box-sub-container-2"}>
                                {[5, 6, 7, 8].map((number) =>
                                    <p>{number} {validatedNumber.includes(number) ? <FaCheck className={"success-icon"} /> : <HiX className={"danger-icon"} />}</p>
                                )}
                            </div>
                        }

                        {maxNumber >= 9 &&
                            <div className={"box-sub-container-2"}>
                                {[9, 10, 11, 12].map((number) =>
                                    <p>{number} {validatedNumber.includes(number) ? <FaCheck className={"success-icon"} /> : <HiX className={"danger-icon"} />}</p>
                                )}
                            </div>
                        }

                        {maxNumber >= 13 &&
                            <div className={"box-sub-container-2"}>
                                {[13, 14, 15, 16].map((number) => {
                                        if (number > maxNumber) return;
                                        return <p>{number} {validatedNumber.includes(number) ? <FaCheck className={"success-icon"} /> : <HiX className={"danger-icon"} />}</p>;
                                    }
                                )}
                            </div>
                        }
                    </div>
                </div> : ""
            }

            {isVisible && (!inputOne || !inputTwo || !inputThree) && validatedNumber2.length ?
                <>
                    <div className={"box-container"}>
                        <div className={"box-grid"}>
                            {validatedNumber2?.map((perm) => <p>{perm?.join(" - ")}</p>)}
                        </div>
                    </div>
                </>
                : ""}

            {isVisible && (!inputOne || !inputTwo || !inputThree) && validatedNumber.length ?
                <>
                    <div className={"box-container"}>
                        <div className={"box-grid"}>
                            {validatedNumber?.map((perm) => <p>{perm?.join(" - ")}</p>)}
                        </div>
                    </div>
                </>
                : ""}
        </div>
    );
}

export default Home;